// src/components/ImageGallery.tsx
import React from 'react';
import { ImageResource } from '../types/ImageResource';
import ImageComponent from './ImageComponent';

interface ImageGalleryProps {
  images: ImageResource[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  return (
    <div className="columns-1 gap-4 sm:columns-2 sm:gap-3 md:columns-3 lg:columns-4 [&>div:not(:first-child)]:mt-3">
      {images.map((image, index) => (
        <ImageComponent key={index} image={image} />
      ))}
    </div>
  );
};

export default ImageGallery;
