import './App.css';
import ImageGallery from './components/ImageGallery';
import { ImageResource } from './types/ImageResource';

const baseURLHighRes = '/src/imgs/high/';
const baseURLLowRes = '/src/imgs/low/';
const images: ImageResource[] = [
  {
    src: `${baseURLLowRes}0S8A2504.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2504.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2507.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2507.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2510.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2510.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2515.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2515.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2516.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2516.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2524.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2524.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2527.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2527.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2528.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2528.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2530.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2530.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2531.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2531.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2541.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2541.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2543.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2543.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2545.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2545.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2546.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2546.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2548.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2548.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2550.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2550.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2557.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2557.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2559.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2559.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2560.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2560.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2561.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2561.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2562.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2562.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2564.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2564.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2565.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2565.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2566.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2566.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2569.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2569.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2570.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2570.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2576.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2576.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2578.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2578.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2579.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2579.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2580.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2580.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2589.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2589.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2594.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2594.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2600.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2600.jpg`,
  },
  {
    src: `${baseURLLowRes}0S8A2611R.jpg`,
    downloadLink: `${baseURLHighRes}0S8A2611R.jpg`,
  },
  {
    src: `${baseURLLowRes}M832467.jpg`,
    downloadLink: `${baseURLHighRes}M832467.jpg`,
  },
  {
    src: `${baseURLLowRes}M832469.jpg`,
    downloadLink: `${baseURLHighRes}M832469.jpg`,
  },
  {
    src: `${baseURLLowRes}M832470.jpg`,
    downloadLink: `${baseURLHighRes}M832470.jpg`,
  },
  {
    src: `${baseURLLowRes}M832471.jpg`,
    downloadLink: `${baseURLHighRes}M832471.jpg`,
  },
];

function App() {
  return (
    <div className="App">
      <div className="p-5 sm:p-8">
        <h1 className="py-6 text-4xl sm:text-5xl md:text-6xl font-bold">
          Välkommen till Pelles Tavlor!
        </h1>
        <p className="mb-4 mx-auto max-w-[83ch] text-base sm:text-lg md:text-m leading-relaxed">
          Jag har målat dessa tavlor och det glädjer mig att nu kunna dela dem
          med alla som tycker om dem. Ni får gärna ladda ner dem och det är
          fritt att använda bilderna såväl privat som kommersiellt.
        </p>
        <p className="mb-4 mx-auto max-w-[70ch] text-base sm:text-lg md:text-m leading-relaxed">
          Med vänliga hälsningar <br />
          Per Karlsson
        </p>
        <p className="mb-4 mx-auto max-w-[70ch] text-base sm:text-2xl md:text-3xl leading-relaxed">
          Free to use
        </p>
        <ImageGallery images={images} />
      </div>
    </div>
  );
}

export default App;
